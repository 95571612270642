import React, { Component } from 'react';
import Footer from './Footer';
import { connect } from 'react-redux';
import { imagePath } from '../actions/ImagePath';
import Header from './Header';
import Loader from './Loader';
import PhoneCode from './PhoneCode';
import { Countries } from './countries';
import dataservice from '../Services/DataService';
import GBLVAR from '../GlobalVariables/globalvaribles';
import { SHOW_COUNTRY, GUEST_ONCHANGE, FALSE_SCREEN, SELECT_COUNTRY, TRUE_SCREEN, SET_NUMBER, GUEST_SIGNUP, INIT_TRANS, PAYMENT_SCREEN, SET_COUNTRY, TRIM_NAME, CHECK_CHANGE, CARD_CHANGE, ON_CHANGE, GET_COUNTRY_DETAILS } from '../constants/actionTypes';
import Cleave from 'cleave.js/react';
import { StateList } from './StateList';
import Select from 'react-select';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { returnCountryInfo, getInfo, returnCodeInfo } from "../Services/Utils";
import _ from 'lodash';
import axios from 'axios';
let CryptoJS;
let click2payInstance;
let click2payEnable = false;
try{
	CryptoJS = window.CryptoJS;
	click2payInstance =  new window.Click2Pay();
	click2payEnable = true;
} catch(err) {
	click2payEnable = false;
}

// var tutelar; 
// let tutelarEnable = false;
// try{
// 	tutelar = new window.Tutelar({"public_key": "tutpk_live_fySLEqoxgW7F"});
// 	tutelarEnable = true;
// } catch(err) {
// 	tutelarEnable = false;
// }

let encryptedCard, cardBrand, checkoutWithNewCard;
var CryptoJSAesJson = {
	'stringify': function (cipherParams) {
		var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
		if (cipherParams.iv) j.iv = cipherParams.iv.toString()
		if (cipherParams.salt) j.s = cipherParams.salt.toString()
		return JSON.stringify(j).replace(/\s/g, '')
	},
	'parse': function (jsonStr) {
		var j = JSON.parse(jsonStr)
		var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
		if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
		if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
		return cipherParams
	}
}

class Home extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		this.state = {
			empty_fields: true,
			phone_empty: true,
			country_code: "",
			country_name: "",
			country_flag: "",
			countryRegion: "",
			ph_number: "",
			guestInfoFromApi: false,
			disableguestPay: false,
			Guest_Error: {},
			loaderRun: false,
			showCard: "",
			validCardName: false,
			selectedCountry: null,
			name: "",
			cardNameVaild: false,
			validCardName: false,
			creditCardType: "",
			cardExpiryMonthValid: false,
			cardExpiryYearValid: false,
			expiry_common: false,
			cardCvvValid: false,
			valid_billing_address: false,
			send_billing_address: false,
			billing_address: "",
			city: "",
			valid_city: false,
			send_city: false,
			state: "",
			valid_state: false,
			send_state: false,
			postal_code: "",
			payment_response: {},
			selectedState: null,
			postalRawValue: "",
			valid_postal_code: false,
			send_postal_code: false,
			Error_Message: "",
			payment_error: {},
			current_country: null,
			current_code: "",
			current_code_dup: "",
			saved_card: false,
			card_list_data: [],
			customer_card_details: {},
			addNewCard: false,
			paymentToken: {},
			paymentTokenStatus: false,
			Apple_Error_Message:"",
			click2payToken:{},
			click2paySavedCardList:[],
			click2paySavedCardListShow:false,
			showClicktoPayWindow:false,
			click2payOtpList:[],
			otp:"",
			clickToPayFrameReturnUser:false,
			requestedValidationChannelId:null,
			c2p_cvv:"",
			Error_Cvv_Message:"",
			currentIp:"",
			addNewCardC2P:false,
			learnmore:false,
			otpErrorReason:"",
			disablec2p:true,
			tutelar_payload_state:""
		}
		this.handlePaste = this.handlePaste.bind(this);
		this.handleKeyEvent = this.handleKeyEvent.bind(this);
		this.defaultGuestDetails = this.defaultGuestDetails.bind(this);
		this.phoneNumberChecking = this.phoneNumberChecking.bind(this);
		this.setPhoneNumber = this.setPhoneNumber.bind(this);
		this.guestLogin = this.guestLogin.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
		this.checkNameFormat = this.checkNameFormat.bind(this);
		this.handleEvent = this.handleEvent.bind(this);
		this.defaultCountry = this.defaultCountry.bind(this);
		this.handleOnCardNameChange = this.handleOnCardNameChange.bind(this);
		this.onCreditCardTypeChanged = this.onCreditCardTypeChanged.bind(this);
		this.onCreditCardChange = this.onCreditCardChange.bind(this);
		this.handleExpiryMonthYear = this.handleExpiryMonthYear.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleOnCvvChange = this.handleOnCvvChange.bind(this);
		this.countryChange = this.countryChange.bind(this);
		this.handlePaymentChange = this.handlePaymentChange.bind(this);
		this.stateChange = this.stateChange.bind(this);
		this.postalCodeChange = this.postalCodeChange.bind(this);
		this.paynow = this.paynow.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.setValidFields = this.setValidFields.bind(this);
		this.encryptMethod = this.encryptMethod.bind(this);
		this.removeError = this.removeError.bind(this);
		this.setValidPostal = this.setValidPostal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.loginEnable = this.loginEnable.bind(this);
		this.deleteCard = this.deleteCard.bind(this);
		this.chooseCard = this.chooseCard.bind(this);
		this.cardCvvChange = this.cardCvvChange.bind(this);
		this.saveCardPayment = this.saveCardPayment.bind(this);
		this.applePay = this.applePay.bind(this);
		this.samsungPay = this.samsungPay.bind(this);
		this.goToCard = this.goToCard.bind(this);
		this.fraudCheck = this.fraudCheck.bind(this);
		this.decryptMethod = this.decryptMethod.bind(this);
		this.handleOtpChange = this.handleOtpChange.bind(this);
		this.changeOtpOption = this.changeOtpOption.bind(this);
		this.payWithReturnUserCard = this.payWithReturnUserCard.bind(this);
		this.handleCvvChange = this.handleCvvChange.bind(this);
		this.onInputCvv = this.onInputCvv.bind(this);
		this.notYourCards = this.notYourCards.bind(this);
		// this.tutelarRef = React.createRef();
	}

	goToCard() {
		this.setState({ showCard: "card_screen", loaderRun: false, validCardName: true }, () => {
			if (this.props.App_Details.card_list === "Yes") {
				var card_list_data = [];
				this.props.App_Details.customer_card_list.map((card_list) => {
					var data = {
						...card_list,
						card_select: false,
						cvv: "",
						validCVV: false
					}
					card_list_data.push(data);
				})
				this.setState({ saved_card: true, card_list_data, customer_address_id: this.props.App_Details.customer_address_id, addNewCard: false })
			} else {
				this.setState({ addNewCard: true })
				this.props.checkChange({ card_save: true })
			}
		})
	}
	samsungPay(){
		var _this = this;
		var orderData = {
			"customer" : {
				"name" : _this.props.state.Guest.name,
				"email" : _this.props.state.Guest.email,
				"phone_code" : _this.props.state.selected_country.country_code,
				"phone_number" : _this.props.state.Guest.ph_number,
				"address" : _this.state.billing_address,
				"state" : _this.state.state,
				"city" : _this.state.city,
				"country" : _this.state.selectedCountry.value,
				"postal_code" : _this.state.postal_code
			},
			"transaction" : {
				"reference_token" : _this.props.App_Details.reference_token
			},
			"merchant" : {
				"payment_gateway": _this.props.App_Details.merchant.payment_gateway,
				"payment_screen_logo" : _this.props.App_Details.merchant.payment_screen_logo,
				"secret_key": _this.props.queryDataObject.secret_key,
				"theme_color": _this.props.App_Details.merchant.theme_color ? _this.props.App_Details.merchant.theme_color.replace('#','') : '1654c3',
				"primary_color": _this.props.App_Details.merchant.primary_color ? _this.props.App_Details.merchant.primary_color.replace('#','') : '000000',
			},
			"transaction_no" : _this.props.App_Details.transaction_no,
			"sender_currency": _this.props.App_Details.sender_currency,
			"payable_amount_in_sender_currency" : _this.props.App_Details.payable_amount_in_sender_currency
		}
		var data = {
			"access_from":"api",
			"reference_id":_this.props.App_Details.reference_token,
			"callback_url": GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungPay+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+_this.props.App_Details.transaction_no+'&payload='+_this.props.App_Details.payload_option
		}
		dataservice.postHValues(GBLVAR.SAMSUNG_PAY, {}, data)
		.then(function (response) {
			var result = response.data.order_response;
			window.SamsungPay.connect(result.id, result.href, '2a1e5396e0764408864eea', GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungPay+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+_this.props.App_Details.transaction_no+'&payload='+_this.props.App_Details.payload_option, GBLVAR.USER_URL+GBLVAR.PAGE_NAME.SamsungCancel+"?orderdata="+encodeURI(JSON.stringify(orderData))+'&transaction_no='+_this.props.App_Details.transaction_no+'&payload='+_this.props.App_Details.payload_option+'&optional1='+_this.props.App_Details.optional1+'&optional2='+_this.props.App_Details.optional2+'&optional3='+_this.props.App_Details.optional3+'&site_return_url='+_this.props.App_Details.site_return_url, 'en_ae', result.encInfo.mod, result.encInfo.exp, result.encInfo.keyId);
		});
	}
	applePay() {
		var _this = this;
		var runningTotal = _this.props.App_Details.transaction_amount;
		var data;
		var paymentRequest = {
			currencyCode: 'AED',
			countryCode: 'AE',
			total: {
				label: 'FOLOOSI',
				amount: runningTotal
			},
			supportedNetworks: ['masterCard', 'visa'],
			merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit']
		};
		var session = new window.ApplePaySession(6, paymentRequest);
		session.onvalidatemerchant = function (event) {
			//var promise = this.performValidation(event.validationURL);
			data = {
				'validation_url': event.validationURL,
				'access_from': 'api',
				// 'access_from': 'payment_link_sandbox',
				'payment_provider' : _this.props.App_Details.merchant.agreement_type
			}
			if(_this.props.App_Details.merchant.payment_gateway == 'ngenius'){
				data.payment_gateway = 'ngenius'
			}
			axios({
				method: 'post',
				url: GBLVAR.APPLEPAY_URL + 'applepay/request-token',
				data: data
			}).then(function (response) {
				//return response.data.data;
				session.completeMerchantValidation(response.data.data);
			}).catch(function (error) {
					console.log(error);
			});
			//console.log(promise);
			/* promise.then(function (merchantSession) {
				session.completeMerchantValidation(merchantSession);
			}); */
		}
		session.onpaymentmethodselected = function (event) {
			var newTotal = { type: 'final', label: 'Foloosi', amount: runningTotal };
			var newLineItems = [];
			session.completePaymentMethodSelection(newTotal, newLineItems);
		}
		session.onpaymentauthorized = function (event) {
			//var promise = this.sendPaymentToken(event.payment.token);
			var paymentToken = event.payment.token;
			var promise = new Promise(function (resolve, reject) {
				_this.setState({ paymentToken: paymentToken });
				/* this.setState({paymentToken:paymentToken.paymentData}, () => {
					console.log("Muthu");
					console.log(this.state.paymentToken);
				}); */
				return resolve(true);
			});
			promise.then(function (success) {
				var status;
				if (success) {
					status = window.ApplePaySession.STATUS_SUCCESS;
					_this.setState({ paymentTokenStatus: true }, () => {
						_this.paynow("apple_pay");
					});
				} else {
					status = window.ApplePaySession.STATUS_FAILURE;
				}
				session.completePayment(status);
			});
		}
		session.oncancel = function (event) {
			console.log(event);
		}
		session.begin();
	}

	saveCardPayment(card) {
		console.log("card.validCVV !== false && card.cvv ===", card)
		if (card.cvv === "" || card.validCVV === true) {
			this.setState({ Error_Message: 'Please fill CVV below' })
		} else {
			console.log(card)
			var customer_card_details = {
				card_customer_token: card.card_customer_token,
				card_customer_email: card.card_customer_email,
				card_customer_password: card.card_customer_password
			}
			this.setState({ customer_card_details, cardCvv: card.cvv }, () => {
				this.paynow("saved_card")
			})
		}

		//return false;
		/* if(is_from === "saved_card"){
			_this.setState({Error_Message:`${'Please fill CVV below'}`}, () => {
				_this.myRef.current.scrollTop = 0;
			});
			return false;
		}	 */
	}

	cardCvvChange(index, value) {
		var card_list_data = this.state.card_list_data;
		console.log("card_list_data[index]", card_list_data[index])
		card_list_data[index].cvv = value;
		if (card_list_data[index].card_type == 'amex' && card_list_data[index].cvv.length < 4) {
			card_list_data[index].validCVV = true;
		} else if (card_list_data[index].cvv.length < 3) {
			card_list_data[index].validCVV = true;
		} else {
			card_list_data[index].validCVV = false;
		}
		this.setState({ card_list_data, Error_Message: "" })
	}

	deleteCard(index,card) {
		// var card_list_data = this.state.card_list_data;
		// card_list_data.splice(index, 1);
		// this.setState({ card_list_data }, () => {
		// 	console.log("card_list_data", this.state.card_list_data)
		// 	if (this.state.card_list_data.length === 0) {
		// 		console.log("App_details", this.props.App_Details)
		// 		this.setState({ saved_card: false })
		// 		this.props.on_change({ card_save: true })
		// 	}
		// })

		var self = this;
        var data = {
            card_id : card.id,
			card_customer_token : card.card_customer_token,
			customer_unique_reference: card.customer_unique_reference
        }

		console.log("card",card)

		console.log("data",data)
		var header = {
			'Authorization': 'Bearer ' + self.props.state.successdetails.authentication,
			'auth_token':self.props.state.successdetails.auth_token,
			'platform': "api",
			'secret_key':self.props.queryDataObject.secret_key,
			'access_for': 'developer_api'
		}
		console.log(header)
		//return false;
		dataservice.postHValues(`${GBLVAR.API_URL}card/delete`, header, data)
		.then(function (response) {
			console.log("response",response)
			if(response.message){
				var card_list_data = self.state.card_list_data;
				card_list_data.splice(index, 1);
				self.setState({ card_list_data }, () => {
					console.log("card_list_data", self.state.card_list_data)
					if (self.state.card_list_data.length === 0) {
						console.log("App_details", self.props.App_Details)
						self.setState({ saved_card: false,addNewCard : true })
						self.props.on_change({ card_save: true })
					}
				}) 
			}
		})
        // ApiGateway.post(`${GBLVAR.BASE_USER_TEST_API_URL}/customer/card/delete`, data, function (response) {
        //     var card_list_data = self.state.card_list_data;
        //     card_list_data.splice(index, 1);
        //     self.setState({ card_list_data }, () => {
        //         console.log("card_list_data", self.state.card_list_data)
        //         if (self.state.card_list_data.length === 0) {
        //             console.log("App_details", self.props.App_Details)
        //             self.setState({ saved_card: false })
		// 			self.props.on_change({ card_save: true })
        //         }
        //     })  
        // })
	}

	chooseCard(index) {
		var card_list_data = this.state.card_list_data;
		card_list_data[index].card_select = true;
		console.log("card_list_data[index]", card_list_data[index])
		var cardNumber = card_list_data[index].card_no;
		this.setState({ card_list_data, cardNumber })
	}

	handleChange(e) {
		var error = { ...this.state.Guest_Error }
		error[e.target.name] = "";
		this.setState({ Guest_Error: error })
	}
	removeError() {
		this.setState({ payment_error: {} })
	}
	encryptMethod(text) {
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_KEY, { format: CryptoJSAesJson }).toString();
		return encrypted;
	}
	decryptMethod(text){
		let decrypted = JSON.parse(CryptoJS.AES.decrypt(text, process.env.REACT_APP_KEY, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
		return decrypted;
	}
	showErrors() {
		var _this = this;
		var cardname = _this.state.validCardName ? '' : 'Name, ';
		var carnumber = _this.state.validCardNumber ? '' : 'Card number, ';
		var cardexpiry = _this.state.validExpiry ? '' : 'Expiry, ';
		var cardcvv = _this.state.validCvv ? '' : 'CVV ';
		_this.setState({ Error_Message: `${'Please fill ' + cardname + carnumber + cardexpiry + cardcvv + 'fields below'}` }, () => {
			_this.myRef.current.scrollTop = 0;
		});
	}
	paynow(is_from) {
		var _this = this;
		var postcode = _this.state.postal_code;
		console.log(_this.state.paymentToken)
		const [cc_first_name, cc_last_name] = _this.state.name.split(' ');
		_this.setState({ loaderRun: true, showCard: "", Error_Message: "" });
		var header = {
			'platform': _this.props.state.successdetails.platform,
			'secret_key': _this.props.queryDataObject.secret_key,
			'access_for': 'developer_api',
			'is_encrypted': true
		}
		let browserDetails = {
			"deviceChannel": "BRW",
			"threeDSCompInd": "Y",
			"notificationURL": GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' + window.App_Details.transaction_no,
			"browserInfo": {
				"browserAcceptHeader": "application\/json",
				"browserJavaEnabled": true,
				"browserLanguage": window.navigator.language || window.navigator.userLanguage,
				"browserTZ": "0",
				"browserUserAgent": window.navigator.userAgent,
				"browserColorDepth": window.screen.colorDepth,
				"browserScreenHeight": window.screen.height,
				"browserScreenWidth": window.screen.width,
				"browserJavascriptEnabled": true,
				"browserIP": this.state.currentIp ? this.state.currentIp : "0.0.0.0",
				"challengeWindowSize": "02"
			}
		}
		if (is_from === "apple_pay") {
			var orderData = {
				"customer" : {
					"name" : _this.props.state.Guest.name,
					"email" : _this.props.state.Guest.email,
					"phone_code" : _this.props.state.selected_country.country_code,
					"phone_number" : _this.props.state.Guest.ph_number,
					"address" : _this.state.billing_address,
					"state" : _this.state.state,
					"city" : _this.state.city,
					"country" : _this.state.selectedCountry.value,
					"postal_code" : postcode
				},
				"transaction" : {
					"reference_token" : _this.props.App_Details.reference_token,
					"success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' + window.App_Details.transaction_no,
					'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + window.App_Details.transaction_no,
				}
			}
			data = {
				order: _this.encryptMethod(orderData),
				apple_pay_token: _this.state.paymentToken
			} 
		} else if(is_from === "click2pay"){
			var orderData = {
				"customer" : {
					"name" : _this.props.state.Guest.name,
					"email" : _this.props.state.Guest.email,
					"phone_code" : _this.props.state.selected_country.country_code,
					"phone_number" : _this.props.state.Guest.ph_number,
					"address" : _this.state.billing_address,
					"state" : _this.state.state,
					"city" : _this.state.city,
					"country" : _this.state.selectedCountry.value,
					"postal_code" : postcode
				},
				"transaction" : {
					"reference_token" : _this.props.App_Details.reference_token,
					"success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' + window.App_Details.transaction_no,
					'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + window.App_Details.transaction_no,
				},
				"card":{
					"cvv":_this.state.c2p_cvv !== '' ? _this.state.c2p_cvv : _this.state.cardCvv
				},
				"browserDetails":browserDetails,
				"dpa_id": _this.props.App_Details['dpa_id']
			}
			data = {
				order: _this.encryptMethod(orderData),
				click2pay_token: _this.state.click2payToken
			} 
		}
		else {
			var orderData = {
				"customer" : {
					"name" : _this.props.state.Guest.name,
					"email" : _this.props.state.Guest.email,
					"phone_code" : _this.props.state.selected_country.country_code,
					"phone_number" : _this.props.state.Guest.ph_number,
					"address" : _this.state.billing_address,
					"state" : _this.state.state,
					"city" : _this.state.city,
					"country" : _this.state.selectedCountry.value,
					"postal_code" : _this.state.postal_code
				},
				"transaction" : {
					"reference_token" : _this.props.App_Details.reference_token,
					"success_url" : GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Success + '?refid=' + window.App_Details.transaction_no,
					'cancel_url': GBLVAR.USER_URL + GBLVAR.PAGE_NAME.Cancel + '?refid=' + window.App_Details.transaction_no,
				},
				"browserDetails":browserDetails
			}
			if (is_from === "saved_card") { 
				orderData.payment = {
					"pay_via_saved" : "Yes",
					"card_customer_token" : _this.state.customer_card_details.card_customer_token,
					"card_customer_email" : _this.state.customer_card_details.card_customer_email,
					"customer_unique_reference" : _this.props.App_Details.customer_unique_reference,
					"card_customer_password" : _this.state.customer_card_details.card_customer_password,
					"customer_address_id" : _this.props.App_Details.customer_address_id
				}
				orderData.card = {
					"card_cvv" : _this.state.cardCvv
				}
			}
			if (is_from === "unsaved_card") {
				orderData.payment = {
					"pay_via_saved" : "No"
				}
				orderData.card = {
					"card_number" : _this.state.cardNumber,
					"card_cvv" : _this.state.cardCvv,
					"card_exp" : _this.state.cardExpiryYear + _this.state.cardExpiryMonth,
					"first_name" : cc_first_name,
					"last_name" : cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name,
					"card_save" : _this.props.state.card_save ? "Yes" : "No"
				}
			}
			console.log("_this.props.card_save", _this.props.state.card_save)
			console.log("encrypt 1", orderData)
			window.card_save = _this.props.state.card_save;
			var data = { order: _this.encryptMethod(orderData) }
		}
		if(this.props.App_Details.payload_option=="seon") {
			data.payload = this.state.payload;
		}
		else{
			try{
				data.tutelar_payload = this.state.tutelar_payload_state;
				data.payload = this.state.payload;	
			} catch(err){
				data.payload = this.state.payload;
			}
			
		}
		data.fraud_check = "enabled";
		console.log("order data 1", orderData)
		dataservice.postHValues(GBLVAR.CHARGE_REQUEST_URL, header, data)
			.then(function (response) {
				console.log("response.data", response.data)
				if (response !== 422 && response.data === undefined) {
					_this.setState({ loaderRun: false, showCard: "card_screen", Error_Message: "Please check the below fields are correct or not" }, () => {
						if (is_from === "saved_card") {
							_this.setState({ saved_card: true })
						} else {
							_this.setState({ saved_card: false })
						}
					});
				} else if (response === 422) {
					_this.props.history.push(GBLVAR.PAGE_NAME.CardCancel);
				} else {
					if (response.data.success == 1) {
						_this.setState({ loaderRun: false }, () => {
							var responseData = response.data;
							if (responseData.payment_gateway == 'paytabpci') {
								_this.setState({ payment_response: response.data }, () => {
									_this.props.cardChange(_this.state.payment_response.card_data)
									window.card_data = _this.state.payment_response.card_data;
									if (_this.state.payment_response.threed_secure == 1) {
										_this.setState({ showCard: "3d Redirect" }, () => {
											var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
											paymentFrameDoc.open();
											paymentFrameDoc.write("<html><body></body></html>");
											paymentFrameDoc.close();
											var form = paymentFrameDoc.createElement("form");
											form.setAttribute("method", "POST");
											form.setAttribute("action", _this.state.payment_response.redirect_url);
											form.setAttribute("enctype", "application/x-www-form-urlencoded");
											paymentFrameDoc.body.appendChild(form);
											form.submit();
										})
									}
									else if (_this.state.payment_response.threed_secure == 0 && _this.state.payment_response.success == 1) {
										_this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess + '?refid=' + _this.props.App_Details.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
									}
									else {
										_this.props.history.push(GBLVAR.PAGE_NAME.Directcancel + '?refid=' + _this.props.App_Details.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
									}
								})
							}
							if (responseData.payment_gateway == 'ngenius') {
								_this.setState({ payment_response: response.data }, () => {
									_this.props.cardChange(_this.state.payment_response.card_data)
									window.card_data = _this.state.payment_response.card_data;
									if (_this.state.payment_response.threed_secure == 1) {
										_this.setState({ showCard: "ngeniusthreedsecure" }, () => {
											var threedView = _this.state.payment_response.threed_view;
											var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
											paymentFrameDoc.open();
											paymentFrameDoc.write("<html><body></body></html>");
											paymentFrameDoc.close();
											var form = paymentFrameDoc.createElement("form");
											form.setAttribute("method", "POST");
											form.setAttribute("action", threedView.acs_url);
											form.setAttribute("enctype", "application/x-www-form-urlencoded");
											var PaReq = paymentFrameDoc.createElement("input"); 
											PaReq.setAttribute("type", "hidden"); 
											PaReq.setAttribute("name", "PaReq"); 
											PaReq.setAttribute("id", "PaReq"); 
											PaReq.setAttribute("value", threedView.acs_page_req);
											var TermUrl = paymentFrameDoc.createElement("input"); 
											TermUrl.setAttribute("type", "hidden"); 
											TermUrl.setAttribute("name", "TermUrl"); 
											TermUrl.setAttribute("id", "TermUrl"); 
											TermUrl.setAttribute("value", decodeURIComponent(threedView.temp_url));
											var MD = paymentFrameDoc.createElement("input"); 
											MD.setAttribute("type", "hidden"); 
											MD.setAttribute("name", "MD"); 
											MD.setAttribute("id", "MD"); 
											MD.setAttribute("value", threedView.acs_md);
											form.appendChild(PaReq); 
											form.appendChild(TermUrl); 
											form.appendChild(MD); 
											paymentFrameDoc.body.appendChild(form);
											form.submit();
										})
									}
									else if (_this.state.payment_response.threed_secure == 2) {
										_this.setState({ showCard: "ngeniusthreedsecure" }, () => {
											var threedView = _this.state.payment_response.threed_view;
											var paymentFrameDoc = document.getElementById("foloosiPluginApiiframe").contentDocument || document.getElementById("foloosiPluginApiiframe").contentWindow.document;
											paymentFrameDoc.open();
											paymentFrameDoc.write("<html><body></body></html>");
											paymentFrameDoc.close();
											var form = paymentFrameDoc.createElement("form");
											form.setAttribute("method", "POST");
											form.setAttribute("action", threedView.acs_url);
											form.setAttribute("enctype", "application/x-www-form-urlencoded");
											var cReq = paymentFrameDoc.createElement("input"); 
											cReq.setAttribute("type", "hidden"); 
											cReq.setAttribute("name", "creq"); 
											cReq.setAttribute("id", "creq"); 
											cReq.setAttribute("value", threedView.c_req);
											form.appendChild(cReq); 
											paymentFrameDoc.body.appendChild(form);
											form.submit();
										})
									}
									else if (_this.state.payment_response.threed_secure == 0 && _this.state.payment_response.success == 1) {
										_this.props.history.push(GBLVAR.PAGE_NAME.Directsuccess + '?refid=' + _this.props.App_Details.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
									}
									else {
										_this.props.history.push(GBLVAR.PAGE_NAME.Directcancel + '?refid=' + _this.props.App_Details.transaction_no + '&transaction_no=' + _this.state.payment_response.transaction_no + '&amount=' + _this.state.payment_response.amount + '&currency=' + _this.state.payment_response.currency + '&site_return_url=' + _this.state.payment_response.site_return_url + '&optional1=' + _this.state.payment_response.optional1 + '&optional2=' + _this.state.payment_response.optional2 + '&optional3=' + _this.state.payment_response.optional3);
									}
								})
							}
						})
					}
					else {
						_this.setState({ loaderRun: false, showCard: "card_screen", Error_Message: "Please check the below fields are correct or not" }, () => {
							if (is_from === "saved_card") {
								_this.setState({ saved_card: true })
							} else {
								_this.setState({ saved_card: false })
							}
						});
					}
				}
		})
	}
	postalCodeChange(e) {
		var _this = this;
		_this.setState({ postalRawValue: e.target.rawValue }, () => {
			_this.setValidPostal();
		})
	}
	setValidPostal() {
		var _this = this;
		if (_this.state.selectedCountry.value === "USA") {
			if (_this.state.postalRawValue.length === 9) {
				const code = _this.state.postalRawValue.slice(0, 5) + "-" + _this.state.postalRawValue.slice(-4)
				_this.setState({ postal_code: code }, () => {
					var regEx = /^\d{5}-\d{4}$/;
					if (!regEx.test(_this.state.postal_code)) {
						_this.setState({ valid_postal_code: true, send_postal_code: false });
					}
					else {
						_this.setState({ valid_postal_code: false, send_postal_code: true });
					}
				})
			}
			else {
				_this.setState({ valid_postal_code: true, send_postal_code: false });
			}
		}
		else {
			if (_this.state.postalRawValue.length === 6) {
				const code = _this.state.postalRawValue.slice(0, 3) + " " + _this.state.postalRawValue.slice(-3)
				_this.setState({ postal_code: code }, () => {
					var regEx = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
					if (!regEx.test(_this.state.postal_code)) {
						_this.setState({ valid_postal_code: true, send_postal_code: false });
					}
					else {
						_this.setState({ valid_postal_code: false, send_postal_code: true });
					}
				})
			}
			else {
				_this.setState({ valid_postal_code: true, send_postal_code: false });
			}
		}
	}
	stateChange = selectedState => {
		var _this = this;
		_this.setState({ selectedState, Error_Message: "" }, () => {
			_this.setState({ state: _this.state.selectedState.value, valid_state: false, send_state: true })
		});
	};
	handlePaymentChange(e) {
		var _this = this;
		var name = e.target.name;
		_this.setState({
			[e.target.name]: e.target.value, Error_Message: ""
		});
	}
	handleCvvChange(e,p) {
		const nodeList = document.querySelectorAll(".credit-card-cvv-input");
		for (let i = 0; i < nodeList.length; i++) {
			if(i !== p){
				nodeList[i].value = "";
			} else{
				this.setState({ c2p_cvv: e.target.value, Error_Cvv_Message: "" });
			}
		}
	}
	onInputCvv(e){
		return e.target.value;// = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
	}
	handleOnCardNameChange(e) {
		var _this = this;
		let error = { ..._this.state.payment_error };
		error[e.target.name] = "";
		_this.setState({
			[e.target.name]: e.target.value, Error_Message: "", payment_error: error
		}, () => {
			if (_this.state.name.length >= 1) {
				if (_this.checkNameFormat(_this.state.name, "name") === false) {
					error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
					_this.setState({ cardNameVaild: true, validCardName: false, payment_error: error }, () => {
					});
				}
				else {
					_this.setState({ cardNameVaild: false, validCardName: true });
				}
			} else {
				_this.setState({ cardNameVaild: true, validCardName: false });
			}
		})
	}
	setValidFields(name) {
		var _this = this;
		let error = { ..._this.state.payment_error };
		error[name] = "";
		_this.setState({ payment_error: error });
		if (name == "postal_code") {
			if (_this.state.postal_code.length >= 1) {
				if (!_this.checkNameFormat(_this.state.postal_code, "postal_code")) {
					_this.setState({ valid_postal_code: true, send_postal_code: false });
				}
				else {
					_this.setState({ valid_postal_code: false, send_postal_code: true });
				}
			}
			else {
				_this.setState({ valid_postal_code: true, send_postal_code: false });
			}
		}
		if (name == "billing_address") {
			if (_this.state.billing_address.length >= 1 && _this.state.billing_address.length <= 100) {
				if (_this.checkNameFormat(_this.state.billing_address, "address") === false) {
					_this.props.checkChange({ show_address: true })
					_this.setState({ valid_billing_address: true, send_billing_address: false }, () => {
						var stateCopy = Object.assign({}, _this.state);
						stateCopy.payment_error.billing_address = 'Address must be contain A-Z, a-z, 0-9, space only';
						_this.setState(stateCopy);
					});
				}
				else {
					_this.setState({ valid_billing_address: false, send_billing_address: true });
				}
			}
			else {
				_this.setState({ valid_billing_address: true, send_billing_address: false });
			}
		}
		if (name == "city") {
			if (_this.state.city.length >= 1) {
				if (_this.checkNameFormat(_this.state.city, "check") === false) {
					_this.props.checkChange({ show_address: true })
					_this.setState({ valid_city: true, send_city: false }, () => {
						var stateCopy = Object.assign({}, _this.state);
						stateCopy.payment_error.city = 'Only English letters and spaces are allowed';
						_this.setState(stateCopy);
					});
				}
				else {
					_this.setState({ valid_city: false, send_city: true });
				}
			}
			else {
				_this.setState({ valid_city: true, send_city: false });
			}
		}
		if (name == "state") {
			if (_this.state.state.length >= 1) {
				if (_this.checkNameFormat(_this.state.state, "check") === false) {
					_this.props.checkChange({ show_address: true })
					_this.setState({ valid_state: true, send_state: false }, () => {
						var stateCopy = Object.assign({}, _this.state);
						stateCopy.payment_error.state = 'Only English letters and spaces are allowed';
						_this.setState(stateCopy);
					});
				}
				else {
					_this.setState({ valid_state: false, send_state: true });
				}
			}
			else {
				_this.setState({ valid_state: true, send_state: false });
			}
		}
	}
	countryChange = selectedCountry => {
		var _this = this;
		_this.setState({ selectedCountry, selectedState: null, state: "", postal_code: "", Error_Message: "" });
	};
	handleOnCvvChange(event) {
		this.setState({ cardCvv: event.target.rawValue, Error_Message: "" }, () => {
			if (this.state.cardCvv.length < 3) {
				this.setState({ cardCvvValid: true, validCvv: false });
			}
			else {
				this.setState({ cardCvvValid: false, validCvv: true });
			}
		});
	}
	onDateChange(event) {
		this.setState({ dateRawValue: event.target.rawValue, Error_Message: "" }, () => {
			if (this.state.dateRawValue.length == 4) {
				this.setState({ cardExpiryMonth: this.state.dateRawValue.slice(0, 2), cardExpiryYear: this.state.dateRawValue.slice(-2) })
			}
			if (this.state.dateRawValue.length < 4) {
				this.setState({ cardExpiryMonth: "", cardExpiryYear: "", expiry_common: true })
			}
		});
	}
	handleExpiryMonthYear(event) {
		var _this = this;
		var now = new Date();
		var currentYear = now.getFullYear().toString().substr(-2);
		var currentMonth = now.getMonth() + 1;
		currentMonth = parseInt(currentMonth);
		currentYear = parseInt(currentYear);
		if (_this.state.cardExpiryYear !== "" && _this.state.cardExpiryMonth !== "") {
			_this.setState({ expiry_common: false })
			var expiryDateYear = parseInt(_this.state.cardExpiryYear);
			var expiryDateMonth = parseInt(_this.state.cardExpiryMonth);
			if (currentYear === expiryDateYear) {
				if (expiryDateMonth < currentMonth) {
					_this.setState({ cardExpiryMonthValid: true, cardExpiryYearValid: true, validExpiry: false });
				} else {
					_this.setState({ cardExpiryMonthValid: false, cardExpiryYearValid: false, validExpiry: true });
				}
			} else {
				if (expiryDateYear < currentYear) {
					_this.setState({ cardExpiryYearValid: true, validExpiry: false, cardExpiryMonthValid: true, });
				} else {
					_this.setState({ cardExpiryYearValid: false, validExpiry: true, cardExpiryMonthValid: false });
				}
			}
		}
		else {
			_this.setState({ expiry_common: true })
		}
	}
	onCreditCardChange(event) {
		// if(this.state.postal_code.length>=1){
		// 	this.setState({send_postal_code:true});
		// }   
		this.setState({ cardNumber: event.target.rawValue, Error_Message: "" }, () => {
			var rupayRegex = /^(508[5-9]|60(80(0|)[^0]|8[1-4]|8500|698[5-9]|699|7[^9]|79[0-7]|798[0-4])|65(2(1[5-9]|[2-9])|30|31[0-4])|817[2-9]|81[89]|820[01])/;
			var discoverRegex = /^6(?:018\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/;
			var card_value = this.state.cardNumber.replace(/\s/g, '');
			if (rupayRegex.test(card_value) === true) {
				this.setState({ cardNumberValid: false, creditCardType: 'rupay', validCardNumber: true });
			}
			else if (discoverRegex.test(card_value) === true) {
				this.setState({ cardNumberValid: false, creditCardType: 'discover', validCardNumber: true });
			}
			else {
				if (this.state.creditCardType == 'unknown' || this.state.creditCardType == 'uatp') {
					this.setState({ cardNumberValid: true, validCardNumber: false });
				}
				else {
					this.setState({ cardNumberValid: false, validCardNumber: true });
				}
			}
		});
	}
	onCreditCardTypeChanged(type) {
		this.setState({ creditCardType: type });
	}
	defaultCountry() {
		var _this = this;
		var countryCode = _this.props.App_Details.billing_country.trim();
		var index = _.findIndex(Countries, { '3digit': countryCode });
		if (index == -1) {
			_this.defaultGuestDetails();
		} else {
			var country = Countries[index];
			_this.setState({ selectedCountry: { label: country.name, value: country["3digit"] }, current_code: country.code,country_flag: country.flag }, () => {
				_this.props.get_country(country).then(() => {
					_this.defaultFields();
					_this.defaultGuestDetails();
				})
			})
		}
	}
	defaultFields() {
		var _this = this;
		var postalcodevalue = this.state.postal_code;
		var billingstate = this.state.state;
		if (_this.state.selectedCountry.value === "USA" || _this.state.selectedCountry.value === "CAN") {
			if (postalcodevalue !== "") {
				_this.setState({ postalRawValue: _this.state.postal_code }, () => {
					_this.setValidPostal();

				})
			}
			else {
				if (_this.state.selectedCountry.value === "USA") {
					_this.setState({ postal_code: '00000-0000', valid_postal_code: false, send_postal_code: true })
				}
				if (_this.state.selectedCountry.value === "CAN") {
					_this.setState({ postal_code: 'A1B 2C3', valid_postal_code: false, send_postal_code: true })
				}
			}
			if (billingstate !== "") {
				var StateNames = []
				StateList.map(function (statename, i) {
					if (_this.state.selectedCountry.label === statename.name) {
						return (
							StateNames = statename.states.map(function (list, j) {
								return (
									{ label: list.name, value: list.abbreviation }
								)
							}, this)
						)
					}
				}, this);
				StateNames.map(function (statename, i) {
					if (statename.name === billingstate) {
						_this.setState({
							valid_state: false, send_state: true,
							selectedState: { label: statename.name, value: statename.abbreviation }
						})
					}
					else {
						_this.setState({ valid_state: true, send_state: false }, () => {
							//console.log("valid_state",_this.state.valid_state)
						})
					}
				}, this);
			}
		}
		else {
			_this.setState({ state: billingstate !== '' ? billingstate : 'Dubai', postal_code: postalcodevalue !== '' ? postalcodevalue : '000000', valid_state: false, send_state: true, valid_postal_code: false, send_postal_code: true }, () => {
				//console.log("postal_code",_this.state.postal_code)
			})
		}
	}
	handleEvent(e) {
		this.setState({ Guest_Error: {} })
	}
	handleKeyEvent(e) {
		var theEvent = e || window.event;
		if (theEvent.type === 'paste') {
			key = e.clipboardData.getData('text/plain');
		} else {
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /^[0-9]+$/;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	}
	handlePaste(e) {
		e.preventDefault();
	}
	defaultGuestDetails() {
		var _this = this;
		if (
			_this.props.state.Guest.email !== '' && _this.props.state.Guest.name !== '' &&
			_this.props.state.Guest.ph_number !== ''
		) {
			_this.setState({ empty_fields: false, name: _this.props.state.Guest.name }, () => {
				_this.phoneNumberChecking();
			})
		}
		else {
			_this.setPhoneNumber();
		}
	}
	setPhoneNumber() {
		var _this = this;
		if (_this.props.state.Guest.name !== "") {
			_this.setState({ name: _this.props.state.Guest.name })
		}
		if (_this.props.state.Guest.ph_number == "") {
			if (_this.props.App_Details.billing_country === "") {
				dataservice.currentCountry(GBLVAR.GET_COUNTRY)
					.then(function (response) {
						Countries.map(country => {
							if (response.countryCode == country["2digit"] || response.countryCode == country["3digit"]) {
								_this.setState({
									country_code: country.code, country_name: country.name, country_flag: country.flag,
									countryRegion: country["2digit"], guestInfoFromApi: false, current_code: country.code
								}, () => {
									_this.props.get_country(country);
									_this.props.guest_screen_false();
								})
							}
						})
					})
			}
			else {
				_this.props.guest_screen_false();
			}
		}
		else {
			_this.setState({ phone_empty: false }, () => {
				_this.phoneNumberChecking();
			})
		}
	}
	loginEnable(phone_number) {
		var _this = this;
		if (!isValidPhoneNumber(_this.state.current_code + phone_number)) {
			if (!isValidPhoneNumber(_this.state.current_code_dup + phone_number)) {
				_this.setState({ guestInfoFromApi: false }, () => {
					_this.props.guest_screen_false();
					_this.setState({ Guest_Error: { ph_number: 'Phone Number Invalid' } });
				})
			}
			else {
				if (_this.state.empty_fields === false) {
					_this.setState({ guestInfoFromApi: true }, () => {
						_this.guestLogin();
					})
				}
				else if (_this.state.phone_empty === false) {
					_this.setState({ guestInfoFromApi: false }, () => {
						_this.props.guest_screen_false();
					})
				}
			}
		}
		else {
			if (_this.state.empty_fields === false) {
				_this.setState({ guestInfoFromApi: true }, () => {
					_this.guestLogin();
				})
			}
			else if (_this.state.phone_empty === false) {
				_this.setState({ guestInfoFromApi: false }, () => {
					_this.props.guest_screen_false();
				})
			}
		}
	}
	phoneNumberChecking() {
		var _this = this;
		const valid = isValidPhoneNumber(_this.props.state.Guest.ph_number);
		const country_detail = parsePhoneNumber(_this.props.state.Guest.ph_number);		
		if (valid) {
			var CountryInfo = returnCountryInfo("+" + country_detail.countryCallingCode);
			var country_data = getInfo(CountryInfo);
			_this.props.set_country(country_data);
			_this.props.set_number(country_detail.nationalNumber);
			if (_this.state.empty_fields === false) {
				_this.setState({ guestInfoFromApi: true }, () => {
					_this.guestLogin();
				})
			}
			if (_this.state.phone_empty === false) {
				_this.setState({ guestInfoFromApi: false }, () => {
					_this.props.guest_screen_false();
				})
			}
		}
		else {
			/* var Guest_Error = { ...this.state.Guest_Error }
			Guest_Error.ph_number = 'Please Enter Valid Phone Number';
			_this.setState({ Guest_Error });
			_this.props.guest_screen_true();
			return false; */
			if (country_detail !== undefined) {
				var CountryInfo = returnCountryInfo("+" + country_detail.countryCallingCode);
				var country_data = getInfo(CountryInfo);
				_this.props.set_country(country_data);
				_this.props.set_number(country_detail.nationalNumber);
				_this.loginEnable(country_detail.nationalNumber);
			} else {
				var CountryInfo = returnCountryInfo(_this.state.current_code);
				var country_data = getInfo(CountryInfo);
				_this.props.set_country(country_data);
				_this.props.set_number(_this.props.state.Guest.ph_number);
				_this.loginEnable(_this.props.state.Guest.ph_number);
			}
		}
	}
	fraudCheck(){
		var self = this;
		window.seon.config({
			session_id: `"${self.props.App_Details.transaction_no}"`,
			audio_fingerprint: true,
			canvas_fingerprint: true,
			webgl_fingerprint: true,
			onSuccess: function(message) {
				console.log("success", message);
				window.seon.getBase64Session(function(data) {
					if (data) {
						//console.log("Session payload", data);
						self.setState({ payload : data })
					} else {
						console.log("Failed to retrieve session data.");
					}
				});
			},
			onError: function(message) {
				console.log("error", message);
			}
		});
	}
	

	handleOtpChange(e){
		var self = this;
		self.setState({otp:e.target.value})
	}
	changeOtpOption(e){
		var self = this;
		self.setState({otp_option:e.target.value});
	}
	//Click2Pay
	async initializeClick2Pay(id) {
		var _this = this;
		const initParams = {
			"srcDpaId": id,
			"cardBrands": [
				"mastercard",
				"visa",
				"amex"
			],
			"dpaTransactionOptions": {
				"dpaLocale": "en_US",
				"paymentOptions":[{
					"dynamicDataType":"NONE"
				}],
				"customInputData":{
					"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
				},
				//"consumerNameRequested": true,
				"consumerEmailAddressRequested": true,
				//"consumerPhoneNumberRequested": true,
			},
			"dpaData": {
				"dpaPresentationName": "Foloosi",
				"dpaName": "Foloosi Technologies LTD"
			}
		}
		console.log(initParams);
		try {
			const result = await click2payInstance.init(initParams);
			console.log("result",result);
			document.getElementById("error_log").innerHTML += "<pre>Init Success : "+JSON.stringify(result)+"</pre>";
			_this.loadInitCall();
		} catch (error) {
			console.log('error', error)
			document.getElementById("error_log").innerHTML += "<pre>Init Error : "+JSON.stringify(error)+"</pre>";
			_this.loadInitCall();
		}
	}
	async getCardsHandler () {
		var self = this;
		// let tutelarInfo = self.tutelarRef.current.getClientDetails();
		try {
			const promiseResolvedPayload = await click2payInstance.getCards();
			console.log('CARDS', promiseResolvedPayload);
			document.getElementById("error_log").innerHTML += "<pre>Get Cards Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			self.props.payment_enable();
			// self.setState({ loaderRun: true, tutelar_payload_state : tutelarInfo || JSON.stringify({tutelar_status:"undefined", ua:window.navigator.userAgent}) }, () => {
				if(promiseResolvedPayload.length >= 1){
					self.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
						self.setState({click2paySavedCardList:promiseResolvedPayload}, () => {
							self.setState({loaderRun:false,click2paySavedCardListShow:true});
						});
					});
				} else{
					//this.proceedToPaymentScreen();
					this.idLookupHandler();
				}
			// });
		} catch (promiseRejectedPayload) {
			console.log("error", promiseRejectedPayload);
			document.getElementById("error_log").innerHTML += "<pre>Get Cards Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	async idLookupHandler () {
		var self = this;
		var customerData = {
			email: self.props.state.Guest.email
			/* mobileNumber: {
				countryCode: self.props.state.selected_country.country_code,
				phoneNumber: self.props.state.Guest.ph_number
			} */
		}
		try {
			console.log("customerData",customerData)
			const promiseResolvedPayload = await click2payInstance.idLookup(customerData);
			console.log("success",promiseResolvedPayload);
			document.getElementById("error_log").innerHTML += "<pre>ID Lookup Data : "+JSON.stringify(customerData)+"</pre>";
			document.getElementById("error_log").innerHTML += "<pre>ID Lookup Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			if(promiseResolvedPayload.consumerPresent){
				self.initiateValidationHandler();
			} else {
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			console.log("error", promiseRejectedPayload);
			document.getElementById("error_log").innerHTML += "<pre>ID Lookup Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	learnmore(){
		var self = this;
		self.setState({learnmore:true}, () => {
			const learnmoreSection = document.querySelector('src-learn-more');
			learnmoreSection.addEventListener('close', event => {
				document.getElementById("error_log").innerHTML += "<pre>Learn more closed : "+JSON.stringify(event)+"</pre>";
				self.setState({learnmore:false});
			});
		});
	}
	showOTPChannelScreen(){
		var self = this;
		const srcOtpChannelSelection = document.querySelector('src-otp-channel-selection');
		srcOtpChannelSelection.identityValidationChannels = self.state.click2payOtpList;
		srcOtpChannelSelection.addEventListener('continue', event => {
			document.getElementById("error_log").innerHTML += "<pre>Continue Channel : "+JSON.stringify(event)+"</pre>";
			self.setState({showCard: "click2payOtpListShow"}, () => {
				self.initiateValidationHandler();
				self.showOTPScreen();
			});
			
		});
		srcOtpChannelSelection.addEventListener('selectChannel', event => {
			console.log('CHANNEL SELECTED: ', event.detail);
			document.getElementById("error_log").innerHTML += "<pre>Select Channel : "+JSON.stringify(event)+"</pre>";
			if(event.detail.identityType.toLowerCase() == 'guest'){
				self.setState({showCard: "card_screen", loaderRun: false}, () => {
					self.proceedToPaymentScreen();
				});
			} else{
				self.setState({maskedValidationChannel:event.detail.maskedValidationChannel, requestedValidationChannelId:event.detail.validationChannelId})
			}
		});
	}
	cardListControl(){
		var self = this;
		const srcCardListSelection = document.querySelector('src-card-list');
		srcCardListSelection.loadCards(self.state.click2paySavedCardList);
		srcCardListSelection.addEventListener('clickAddCardLink', event => { 
			console.log('Add a Card button clicked: ', event);
			document.getElementById("error_log").innerHTML += "<pre>Add Card Click : "+JSON.stringify(event)+"</pre>";
			self.setState({ showCard:"card_screen",addNewCard: true, Error_Message: "",validCardName: true })
		});
		srcCardListSelection.addEventListener('clickSignOutLink', event => { 
			console.log('Not your cards button clicked: ', event);
			document.getElementById("error_log").innerHTML += "<pre>Not your Cards Clicked : "+JSON.stringify(event)+"</pre>";
			self.proceedToPaymentScreen();
		});
		srcCardListSelection.addEventListener('selectSrcDigitalCardId', event => {
			console.log('A card was clicked: ', event.detail);
			document.getElementById("error_log").innerHTML += "<pre>Card Clicked : "+JSON.stringify(event)+"</pre>";
			self.payWithReturnUserCard({srcDigitalCardId:event.detail});
		});
	}
	showOTPScreen(){
		var self = this;
		document.querySelector('src-otp-input').addEventListener('alternateRequested', event => {
			console.log('ALTERNATE CHANNEL REQUESTED: ', event);
			document.getElementById("error_log").innerHTML += "<pre>Channel Change : "+JSON.stringify(event)+"</pre>";
			self.setState({showCard: "showC2POTPChannelScreen", loaderRun: false}, () => {
				self.showOTPChannelScreen();
			});
		});
		document.querySelector('src-otp-input').addEventListener('continue', event => {
			console.log('CONTINUE EVENT: ', event);
			document.getElementById("error_log").innerHTML += "<pre>Continue with selected channel : "+JSON.stringify(event)+"</pre>";
			self.validateCard();
		});
		document.querySelector('src-otp-input').addEventListener('otpChanged', event => {
			console.log('OTP CHANGED: ', event);
			document.getElementById("error_log").innerHTML += "<pre>OTP Changed : "+JSON.stringify(event)+"</pre>";
			self.setState({otp:event.detail});
		});
	}
	async initiateValidationHandler () {
		var self = this;
		try {
			let promiseResolvedPayload;
			//requestedValidationChannelId:'EMAIL' or 'SMS'
			if(self.state.requestedValidationChannelId){
		  		promiseResolvedPayload = await click2payInstance.initiateValidation({requestedValidationChannelId:self.state.requestedValidationChannelId});
			}
			else{
				promiseResolvedPayload = await click2payInstance.initiateValidation();
			}
			document.getElementById("error_log").innerHTML += "<pre>OTP Validation Success : "+promiseResolvedPayload+"</pre>";
			if(promiseResolvedPayload.supportedValidationChannels.length >= 1){
				self.props.payment_enable();
				self.setState({ loaderRun: false},() => {
					console.log("promiseResolvedPayload",promiseResolvedPayload)
					self.setState({click2payOtpList:promiseResolvedPayload.supportedValidationChannels,maskedValidationChannel:promiseResolvedPayload.maskedValidationChannel}, () => {
						self.setState({showCard: "click2payOtpListShow", loaderRun: false,click2payOtpListShow:true}, () => {
							self.showOTPScreen();
						});
					});
				})
			} else{
				this.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) { 
			this.proceedToPaymentScreen();
			document.getElementById("error_log").innerHTML += "<pre>OTP Validation Error : "+promiseRejectedPayload+"</pre>";
		}
	}
	validateCard(){
		var self = this;
		self.props.payment_enable();
		self.validateHandler(this.state.otp);
		/* self.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
			self.validateHandler(this.state.otp);
		}); */
	}
	renderOTP(){
		return (<src-otp-input id="src-otp-input" error-reason={this.state.otpErrorReason} locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" masked-identity-value={this.state.maskedValidationChannel} network-id="Mastercard" type="" is-successful="false" display-header="true" auto-submit="false" disable-elements="false" hide-loader="false" ></src-otp-input>)
	}
	async validateHandler () {
		try {
			const promiseResolvedPayload = await click2payInstance.validate({value:this.state.otp});
			document.getElementById("error_log").innerHTML += "<pre>OTP Verify Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			if(promiseResolvedPayload.length>=1){
				/* if(promiseResolvedPayload.length == 1){
					this.setState({ loaderRun: true,showCard:"single_card_screen",panLastFour:promiseResolvedPayload.panLastFour});
				} else{
					this.setState({ loaderRun: true,showCard:"multiple_card_screen"});
				}
				this.setState({click2paySavedCardList:promiseResolvedPayload}, () => {
					this.setState({loaderRun: false,showCard:"multiple_card_screen" }, () => {
						this.cardListControl();
					});
				}); */
				this.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
					this.setState({click2paySavedCardList:promiseResolvedPayload}, () => {
						this.setState({loaderRun: false });
					})
				});
			} else{
				this.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			if(JSON.parse(JSON.stringify(promiseRejectedPayload)).reason){
				this.setState({otpErrorReason:JSON.parse(JSON.stringify(promiseRejectedPayload)).reason}, () => {
					this.setState({showCard: "click2payOtpListShow", loaderRun: false,click2payOtpListShow:true}, () => {
						document.getElementById("error_log").innerHTML += "<pre>OTP Verify Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
						/* this.setState({otpErrorReason:""}, () => {
							document.getElementById('src-otp-input-element').innerHTML = `<src-otp-input id="src-otp-input" error-reason="${this.state.otpErrorReason}" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" masked-identity-value="${this.state.maskedValidationChannel}" network-id="Mastercard" type="" is-successful="false" display-header="true" auto-submit="false" disable-elements="false" hide-loader="false" ></src-otp-input>`;
						}, () => {
							this.showOTPScreen();
						}); */
						//this.showOTPScreen();
					});
				});
			} else {
				this.proceedToPaymentScreen();
			}
		}
	}
	async encryptCard () {
		var self = this;
		self.setState({showCard:"click2payNewUserFrame",loaderRun: true});
		const [cc_first_name, cc_last_name] = self.state.name.split(' ');
		var cardData;
		if(this.state.creditCardType == 'visa'){
			cardData = {
				"primaryAccountNumber": self.state.cardNumber,
				"panExpirationMonth": self.state.cardExpiryMonth,
				"panExpirationYear": self.state.cardExpiryYear,
				"cardSecurityCode": self.state.cardCvv,
				"cardholderFirstName": cc_first_name,
				"cardholderLastName": cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name
			}
		} else {
			cardData = {
				"primaryAccountNumber": self.state.cardNumber,
				"panExpirationMonth": self.state.cardExpiryMonth,
				"panExpirationYear": self.state.cardExpiryYear,
				"cardSecurityCode": self.state.cardCvv,
				"cardholderFirstName": cc_first_name,
				"cardholderLastName": cc_last_name !== '' && cc_last_name !== undefined ? cc_last_name : cc_first_name,
				"billingAddress": {
					"name": self.props.state.Guest.name,
					"line1": self.state.billing_address,
					"line2": self.state.billing_address,
					"line3": self.state.billing_address,
					"city": self.state.city,
					"state": self.state.state,
					"zip": self.state.postal_code,
					"countryCode": self.state.selectedCountry.value 
				}
			}
		}
		//var cardData = {"primaryAccountNumber": "4622943127011022", "panExpirationMonth": "12", "panExpirationYear": "22", "cardSecurityCode": "665", "cardholderFirstName": "muthu", "cardholderLastName": "d"}
		console.log("cardData1",cardData)
		try {
			const encryptCard = await click2payInstance.encryptCard(cardData);
			console.log("encryptCard-muthu",encryptCard)
			encryptedCard = encryptCard.encryptedCard;
			cardBrand = encryptCard.cardBrand;   
			//var srcWindow = window.open('', '_blank', 'popup')
			//srcWindow.moveTo(500, 100);
			//srcWindow.resizeTo(550, 650);
			self.setState({loaderRun: false});
			//var srcWindow = document.getElementById("clickToPayFrame").contentWindow;
			var checkoutIframe = document.getElementById("clickToPayFrame");
			var srcWindow = checkoutIframe.contentWindow ? checkoutIframe.contentWindow : checkoutIframe.contentDocument.defaultView;
			srcWindow.addEventListener('message', this.cancel);
			//window.childSrcWindow = srcWindow;
			//document.getElementById("error_log").innerHTML += "<pre>Card Data : "+JSON.stringify(cardData)+"</pre>";
			//document.getElementById("error_log").innerHTML += "<pre>Encypted Card Data : "+JSON.stringify(encryptCard)+"</pre>";
			checkoutWithNewCard = {
				"windowRef": srcWindow,
				"encryptedCard": encryptedCard,
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				},
				"consumer": {
					"emailAddress": self.props.state.Guest.email,
					"mobileNumber": {
						"phoneNumber": self.props.state.Guest.ph_number,
						"countryCode": self.props.state.selected_country.country_code.replace(/[^a-zA-Z0-9 ]/g, '')
					}
				},
				"cardBrand": cardBrand/* ,
				"consumer": {
					"emailAddress": self.props.state.Guest.email,
					"mobileNumber": {
						"phoneNumber": self.props.state.Guest.ph_number,
						"countryCode": self.props.state.selected_country.country_code
					}
				},
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				} */
				}
				console.log("checkoutWithNewCard",checkoutWithNewCard);
				//document.getElementById("error_log").innerHTML += "<pre>Encrypt Card Error : "+JSON.stringify(error)+"</pre>";
				this.checkoutWithNewCardHandler();
			} catch (error) {
				console.log("error", error);
				document.getElementById("error_log").innerHTML += "<pre>Encrypt Card Error : "+JSON.stringify(error)+"</pre>";
				self.paynow('unsaved_card');
			}
		//})
	}
	async checkoutWithNewCardHandler(){ 
		console.log("in",checkoutWithNewCard);
		var self = this;
		try {
			console.table("ssss",checkoutWithNewCard);
			//document.getElementById("error_log").innerHTML += "<pre>Checkout with New card check : "+JSON.stringify(checkoutWithNewCard)+"</pre>";
			//return false;
			const promiseResolvedPayload = await click2payInstance.checkoutWithNewCard(checkoutWithNewCard) 
			console.log("checkoutWithNewCard-success",JSON.stringify(promiseResolvedPayload));
			document.getElementById("error_log").innerHTML += "<pre>Checkout with New Card Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			//window.childSrcWindow.close();
			// document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseResolvedPayload.checkoutActionCode == "COMPLETE"){
				try {                            
					console.log(promiseResolvedPayload)
					self.setState({click2payToken:promiseResolvedPayload}, () => {
						self.paynow('click2pay');
					})
				}
				catch(err) {
					console.log(err.message);
				}
			} else if(promiseResolvedPayload.checkoutActionCode == "CHANGE_CARD"){
				self.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
					self.setState({loaderRun:false,click2paySavedCardListShow:true});
				});
			} else if(promiseResolvedPayload.checkoutActionCode == "SWITCH_CONSUMER"){
				self.idLookupHandler();
			} else if(promiseResolvedPayload.checkoutActionCode == "CANCEL"){
				this.setState({disablec2p:false,}, () => {
					this.proceedToPaymentScreen();
					this.props.checkChange({ click2pay_agree_card: false})
					});
			} else if(promiseResolvedPayload.checkoutActionCode == "ERROR"){
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {			
			//console.log("checkoutWithNewCard-error",JSON.stringify(promiseRejectedPayload));
			document.getElementById("error_log").innerHTML += "<pre>Checkout with New Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
			//window.childSrcWindow.close();
			//document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseRejectedPayload.reason == "CARD_INVALID"){
				this.setState({disablec2p:false,}, () => {
					this.proceedToPaymentScreen();
					this.props.checkChange({ click2pay_agree_card: false})
				});
				self.setState({loaderRun:false,click2paySavedCardListShow:true});
			} else if(promiseRejectedPayload.reason == "INVALID_PARAMETER"){
				this.setState({disablec2p:false,}, () => {
					this.proceedToPaymentScreen();
					this.props.checkChange({ click2pay_agree_card: false})
				});
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				// self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		}
	}
	async notYourCards(){
		try {
			const promiseResolvedPayload = await click2payInstance.signOut();
			console.log("signout",JSON.stringify(promiseResolvedPayload));
			this.props.guest_screen_true();
		} catch (promiseRejectedPayload) {
			console.log("checkoutWithNewCard-error",JSON.stringify(promiseRejectedPayload));
			document.getElementById("error_log").innerHTML += "<pre>Checkout with New Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
		}
	}
	cancel(){
		
	}
	payWithReturnUserCard(cardObj){
		var self = this;
		if(self.state.c2p_cvv !== ''){
			self.setState({ Error_Cvv_Message:""});
			self.props.payment_enable();
			self.setState({showCard:"click2payReturnUserFrame",clickToPayFrameReturnUser:true,loaderRun: true }, () => {
				self.checkoutWithReturnUserHandler(cardObj);
			});
		} else{
			self.setState({ Error_Cvv_Message:"Please Enter CVV"});
		}
	}
	async checkoutWithReturnUserHandler(cardObj){
		var self = this;
		/* var srcWindow = window.open('', '_blank', 'popup')
		srcWindow.moveTo(500, 100);
		srcWindow.resizeTo(550, 650);
		srcWindow.addEventListener('message', this.cancel);
		window.childSrcWindow = srcWindow;    */    
		console.log("cardobj",cardObj);
		document.getElementById("error_log").innerHTML += "<pre>Muthu Check Card Data : "+JSON.stringify(cardObj)+"</pre>";
		var checkoutIframe = document.getElementById("clickToPayFrameReturnUser");
		var srcWindow = checkoutIframe.contentWindow ? checkoutIframe.contentWindow : checkoutIframe.contentDocument.defaultView;
		//var srcWindow = document.getElementById("clickToPayFrame").contentWindow;
		srcWindow.addEventListener('message', this.cancel);
		//srcWindow.addEventListener('message', this.cancel);
		try {
			var checkoutWithReturnUserCard = {
				"srcDigitalCardId" : cardObj.srcDigitalCardId,
				"windowRef": srcWindow,
				"dpaTransactionOptions": {
					"dpaLocale": "en_US",
					"customInputData":{
						"com.mastercard.dcfExperience":"PAYMENT_SETTINGS"
					}
				}
			};
			console.log("checkoutWithReturnUserCard-Info",checkoutWithReturnUserCard);
			document.getElementById("error_log").innerHTML += "<pre>Return User Card Data : "+checkoutWithReturnUserCard+"</pre>";
			const promiseResolvedPayload = await click2payInstance.checkoutWithCard(checkoutWithReturnUserCard)
			document.getElementById("error_log").innerHTML += "<pre>Return User with Card Success : "+JSON.stringify(promiseResolvedPayload)+"</pre>";
			console.log("checkoutWithReturnUserCard-success",JSON.stringify(promiseResolvedPayload));
			//window.childSrcWindow.close();
			document.getElementById("clickToPayFrameReturnUser").style.display = "none";
			if(promiseResolvedPayload.checkoutActionCode == "COMPLETE"){
				try {                            
					console.log(promiseResolvedPayload)
					self.setState({click2payToken:promiseResolvedPayload}, () => {
						self.paynow('click2pay');
					})
				}
				catch(err) {
					console.log(err.message);
				}
			} else if(promiseResolvedPayload.checkoutActionCode == "CHANGE_CARD"){
				self.setState({ loaderRun: true,showCard:"click2pay_screen" }, () => {
					self.setState({loaderRun:false,click2paySavedCardListShow:true});
				});
			} else if(promiseResolvedPayload.checkoutActionCode == "SWITCH_CONSUMER"){
				self.idLookupHandler();
			} else if(promiseResolvedPayload.checkoutActionCode == "CANCEL"){
				self.proceedToPaymentScreen();
			} else if(promiseResolvedPayload.checkoutActionCode == "ERROR"){
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		} catch (promiseRejectedPayload) {
			console.log("checkoutWithReturnUserCard-error",JSON.stringify(promiseRejectedPayload));
			document.getElementById("error_log").innerHTML += "<pre>Return User with Card Error : "+JSON.stringify(promiseRejectedPayload)+"</pre>";
			//document.getElementById("clickToPayFrame").style.display = "none";
			if(promiseRejectedPayload.reason == "CARD_INVALID"){
				self.setState({loaderRun:false,click2paySavedCardListShow:true});
			} else if(promiseRejectedPayload.reason == "INVALID_PARAMETER"){
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			} else{
				//self.props.history.push(GBLVAR.PAGE_NAME.C2PCardCancel);
				self.proceedToPaymentScreen();
			}
		}
	}
	loadInitCall(){
		var _this = this;
		window.App_Details = this.props.App_Details;
		window.queryDataObject = this.props.queryDataObject;
		dataservice.currentCountry(GBLVAR.GET_COUNTRY)
		.then(function (response) {
			_this.setState({currentIp:response.query});
			_this.setState({ billing_address: _this.props.App_Details.customer_address !== '' ? _this.props.App_Details.customer_address : response.city, city: _this.props.App_Details.customer_city !== '' ? _this.props.App_Details.customer_city : response.city,postal_code: _this.props.App_Details.billing_postal_code !== '' ? _this.props.App_Details.billing_postal_code : response.zip, state: _this.props.App_Details.billing_state !== '' ? _this.props.App_Details.billing_state : response.regionName }, () => {
				if (_this.state.billing_address !== '') {
					_this.setState({ billing_address: _this.state.billing_address.trim() })
				}
				if (_this.state.city !== "") {
					_this.setState({ city: _this.state.city.trim() })
				}
				if (_this.state.state !== "") {
					_this.setState({ state: _this.state.state.trim() })
				}
				if (_this.state.postal_code !== "") {
					_this.setState({ postal_code: _this.state.postal_code.trim() })
				}
			})
			if (response.status === "success") {
				var country = returnCodeInfo(response.countryCode);
				var select = { label: country.name, value: country["3digit"] };
				_this.setState({ selectedCountry: select, current_country: select, current_code: country.code, current_code_dup: country.code, country_flag: country.flag}, () => {
					_this.props.get_country(country).then(() => {
						_this.defaultFields();
						if (_this.props.App_Details.billing_country !== "") {
							_this.defaultCountry();
						}
						else {
							_this.defaultGuestDetails();
						}
					})
				})
			}
			else {
				var country = returnCodeInfo("AE");
				var select = { label: country.name, value: country["3digit"] };
				_this.setState({ selectedCountry: select, current_country: select, current_code: country.code, current_code_dup: country.code, country_flag: country.flag }, () => {
					_this.props.get_country(country).then(() => {
						_this.defaultFields();
						if (_this.props.App_Details.billing_country !== "") {
							_this.defaultCountry();
						}
						else {
							_this.defaultGuestDetails();
						}
					})
				})
			}
		})
		
		
		if (Number(_this.props.App_Details.transaction_amount.toString().replace(/[^0-9.-]+/g, "")) <= Number(_this.props.App_Details.guest_api_limit)) {
			_this.setState({ disableguestPay: true })
		}
		else {
			_this.setState({ disableguestPay: false })
		}
		if (_this.props.App_Details.merchant !== undefined && _this.props.App_Details.custom_design_applicable == 1) {
			document.documentElement.style.setProperty('--main-theme-color', _this.props.App_Details.merchant.theme_color);
			document.documentElement.style.setProperty('--main-primary-color', _this.props.App_Details.merchant.primary_color);
		}
	}
	loadTutelarSdk(){
		var self = this;
		let tutelarRef = new window.Tutelar({ public_key: "tutpk_live_fySLEqoxgW7F" });
		tutelarRef.then((resp) => {
			self.setState({tutelar_payload_state:resp.getClientDetails()})
		}).catch((err) => {
			console.log(err);
			self.setState({tutelar_payload_state:err})
		});
	}
	componentDidMount() {
		this.loadTutelarSdk();

		var _this = this;
		console.log("_this.props.App_Details",_this.props.App_Details)
		if(_this.props.App_Details.status == '422'){
			_this.props.history.push(GBLVAR.PAGE_NAME.LinkPaid);
		} else{
			_this.fraudCheck();
			//_this.props.App_Details['click2pay'] = true;
			if(click2payEnable && _this.props.App_Details['click2pay'] && _this.props.App_Details['dpa_id']){
				_this.initializeClick2Pay(_this.props.App_Details['dpa_id']); 
			} else{
				if (_this.props.App_Details.transaction_no !==undefined) {
				_this.loadInitCall();
			}else{
				 var queryparams =localStorage.getItem('params');
				 queryparams=JSON.parse(queryparams);
				this.props.history.push(`/?{"reference_token":"${queryparams.reference_token}","secret_key":"${queryparams.secret_key}"}`)
				 
			}
			}
		}
	} 
	validateEmail(email) {
		var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
		return re.test(email);
	}
	checkNameFormat(name, key) {
		var regName = key === "name" ? /^[a-zA-Z][a-zA-Z ]*$/ : key === "address" ? /^[a-zA-Z0-9][a-zA-Z_0-9 ]*$/ :
			key === "check" ? /^[a-zA-Z][a-zA-Z ]*$/ : /^\S.*/;
		if (regName.test(name)) {
			return name;
		} else {
			return false;
		}
	}
	proceedToPaymentScreen(){
		var _this = this;
		// let tutelarInfo = _this.tutelarRef.current.getClientDetails();
		var header = {
			'access_for': 'developer_api'
		}
		var data = {
			'email': _this.props.state.Guest.email,
			'name': _this.props.state.Guest.name.trim(),
			'phone_number': _this.props.state.Guest.ph_number,
			'phone_code': _this.props.state.selected_country.country_code,
			'customer_address': _this.props.App_Details.customer_address,
			'customer_city': _this.props.App_Details.customer_city
		}
		_this.setState({ name: _this.props.state.Guest.name })
		_this.props.payment_enable();
		// _this.setState({ loaderRun: true, tutelar_payload_state : tutelarInfo || JSON.stringify({tutelar_status:"undefined", ua:window.navigator.userAgent}) }, () => {
			setTimeout(() => {
				if(_this.state.click2paySavedCardListShow){
					_this.setState({ showCard: "click2pay_screen", loaderRun: false, validCardName: true })
				}
				else if (_this.props.App_Details.payment_modes.apple_pay && window.ApplePaySession && window.ApplePaySession.canMakePayments) {
					_this.setState({ showCard: "apple_card_screen", loaderRun: false, validCardName: true })
				}
				else if(_this.props.App_Details.payment_modes.samsung_pay){
					_this.setState({ showCard: "samsung_pay_screen", loaderRun: false, validCardName: true })
				}
				else { 
					_this.setState({ showCard: "card_screen", loaderRun: false, validCardName: true }, () => {
						if (_this.props.App_Details.card_list === "Yes") {
							var card_list_data = [];
							_this.props.App_Details.customer_card_list.map((card_list) => {
								var data = {
									...card_list,
									card_select: false,
									cvv: "",
									validCVV: false
								}
								card_list_data.push(data);
							})
							_this.setState({ saved_card: true, card_list_data, customer_address_id: _this.props.App_Details.customer_address_id, addNewCard: false })
						} else {
							_this.setState({ addNewCard: true })
							_this.props.checkChange({ card_save: true })
						}
					})
				}
			}, 2000);
		// })
	}
	guestLogin() {
		var _this = this;
		var errMsg = 0;
		var Guest_Error = { ...this.state.Guest_Error }
		if (_this.state.guestInfoFromApi !== true) {
			if (!_this.props.state.Guest.email) {
				Guest_Error.email = 'Please Enter Email';
				_this.setState({ Guest_Error });
				errMsg += 1;
				return false;
			}
			if (_this.props.state.Guest.email) {
				if (_this.validateEmail(_this.props.state.Guest.email) === false) {
					Guest_Error.email = 'Email is not valid';
					_this.setState({ Guest_Error });
					errMsg += 1;
					return false;
				}
			}
			if (!_this.props.state.Guest.name) {
				Guest_Error.name = 'Please Enter Name';
				_this.setState({ Guest_Error });
				errMsg += 1;
				return false;
			}
			if (_this.props.state.Guest.name) {
				_this.props.trimName(_this.props.state.Guest.name.trim()).then(() => {
					if (_this.props.state.Guest.name[0] !== undefined && !_this.props.state.Guest.name[0].match(/[a-z]/i)) {
						Guest_Error.name = 'Name Starts With English Letters Only';
						_this.setState({ Guest_Error });
						errMsg += 1;
						return false;
					}
				})
			}
			if (_this.props.state.Guest.name) {
				if (_this.checkNameFormat(_this.props.state.Guest.name.trim(), "name") === false) {
					Guest_Error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
					_this.setState({ Guest_Error });
					errMsg += 1;
					return false;
				}
			}
			if (!_this.props.state.Guest.ph_number) {
				Guest_Error.ph_number = 'Please Enter Phone Number';
				_this.setState({ Guest_Error });
				errMsg += 1;
				return false;
			}
			if (_this.props.state.Guest.ph_number.charAt(0) == "0") {
				_this.props.state.Guest.ph_number = _this.props.state.Guest.ph_number.slice(1);
			}
			if (!isValidPhoneNumber(_this.props.state.selected_country.country_code + _this.props.state.Guest.ph_number)) {
				Guest_Error.ph_number = 'Phone Number Invalid';
				_this.setState({ Guest_Error });
				errMsg += 1;
				return false;
			}
		}
		if (errMsg == 0) {
			if (_this.validateEmail(_this.props.state.Guest.email) === false) {
				Guest_Error.email = 'Email is not valid';
				_this.props.guest_screen_false();
				_this.setState({ Guest_Error, guestInfoFromApi: false });
				return false;
			} else if (_this.checkNameFormat(_this.props.state.Guest.name.trim(), "name") === false) {
				Guest_Error.name = 'Please Enter Valid Name, Example: Omar Ali (Only English letters allowed)';
				_this.props.guest_screen_false();
				_this.setState({ Guest_Error, guestInfoFromApi: false });
				return false;
			} else if (!_this.props.state.Guest.ph_number) {
				Guest_Error.ph_number = 'Please Enter Phone Number';
				_this.props.guest_screen_false();
				_this.setState({ Guest_Error, guestInfoFromApi: false });
				return false;
			} else if (!isValidPhoneNumber(_this.props.state.selected_country.country_code + _this.props.state.Guest.ph_number)) {
				Guest_Error.ph_number = 'Phone Number Invalid';
				_this.props.guest_screen_false();
				_this.setState({ Guest_Error, guestInfoFromApi: false });
				return false;
			} else {
				//if(!_this.props. || _this.props.App_Details['click2pay']){
				//let tutelarInfo = this.tutelarRef.current.getClientDetails();
				//this.setState({tutelar_payload_state : tutelarInfo || JSON.stringify({tutelar_status:"undefined", ua:window.navigator.userAgent})}, () => {
					if(_this.props.App_Details['click2pay']){
						_this.setState({ loaderRun: true }, () => {
							this.getCardsHandler();
						});
					} else {
						_this.proceedToPaymentScreen();
					}
				//});
			}
		}
	}
	render() {
		const countryList = Countries.map(function (country, i) {
			return (
				{ label: country.name, value: country["3digit"] }
			)
		}, this);
		var StateNames = []
		StateList.map(function (statename, i) {
			if (this.state.selectedCountry !== null) {
				if (this.state.selectedCountry.label === statename.name) {
					return (
						StateNames = statename.states.map(function (list, j) {
							return (
								{ label: list.name, value: list.abbreviation }
							)
						}, this)
					)
				}
			}
		}, this);
		const customStyles = {
			control: () => ({
				border: '0', minHeight: 'auto', boxShadow: 'none', float: 'left', width: '100%'
			}),
			indicatorsContainer: () => ({
				padding: '0', float: 'left', width: '30px'
			}),
			valueContainer: () => ({
				padding: '0 10px 0 0', float: 'left', width: 'calc(100% - 30px)', position: 'relative'
			}),
			indicatorSeparator: () => ({
				marginRight: '2px', backgroundColor: '#e1e1e1', width: '1px', float: 'left', height: '12px', marginTop: '5px'
			}),
			dropdownIndicator: () => ({
				padding: '0', float: 'left', width: '25px', height: '18px'
			}),
			input: () => ({
				margin: '0px', padding: '0px'
			})
		}
		return (
			<>
			<Header />
			<div className="fl_pay_section" ref={this.myRef}>
					{
						this.props.state.paymentframe
							?
							<>
							{this.state.showCard === "click2payNewUserFrame" ?
										<div className="add_new_wrap">
											
											<a onClick={e => this.proceedToPaymentScreen()}>Pay with onther way</a>
											
										</div> : null
									}
							<div className="frame-open foloosiPluginApiiframeWrapper">
								{this.state.loaderRun === true && this.state.showCard === "" &&
									<Loader />
								}
								{
									this.state.showCard === "samsung_pay_screen" ?
									<div className="paymode_section">
										<div className="paymode_section_btn_warp">
											<a className="paymode_section_credit" onClick={e => this.goToCard()}>Pay with Card</a>
										</div>
										<div className="paymode_section_split">OR</div>
										<div className="paymode_section_btn_warp">
											<a onClick={e => this.samsungPay()}>
												<img src={imagePath('./samsung.png')} alt="" />
											</a>
										</div>
									</div>
									:
									this.state.showCard === "card_screen" ?
										<>
											{
												this.state.saved_card && !this.state.addNewCard ?
													<>
														<div className="add_new_wrap">
															<a onClick={e => { this.setState({ addNewCard: true, Error_Message: "" }); this.props.checkChange({ card_save: true }) }}>Add New Card</a>
														</div>
														{
															this.state.Error_Message &&
															<div className="error_message_top">{this.state.Error_Message}</div>
														}
														{this.state.card_list_data.map((card_list, i) => {
															return (
																<div className="save_card" key={i}> 
																	<div className="save_card_left">
																		<div className="save_card_number">xxxx xxxx xxxx {card_list.card_no}</div>
																		<div className="cvv_wrap">
																			<div className="save_card_cvv">
																				{/* <input type="password" name="card_list_cvv" placeholder="CVV" 
																	value={this.state.card_list_cvv} onChange={e=>this.cardCvvChange(i,e.target.value)}/> */}
																				{card_list.card_type == 'amex' ?
																					<Cleave className="pay_popup_card_input"
																						style={{ borderColor: card_list.validCVV ? 'red' : '#e1e1e1' }} type="password" placeholder="••••" maxLength="4" options={{ numericOnly: true }}
																						onChange={e => this.cardCvvChange(i, e.target.value)} ref="cvvinput" name="cvvinput" tabIndex="4" />
																					:
																					<Cleave className="pay_popup_card_input"
																						style={{ borderColor: card_list.validCVV ? 'red' : '#e1e1e1' }} type="password" placeholder="•••" maxLength="3" options={{ numericOnly: true }}
																						onChange={e => this.cardCvvChange(i, e.target.value)} ref="cvvinput" name="cvvinput" tabIndex="4" />
																				}
																			</div>
																			<a className="save_card_proceed" onClick={e => this.saveCardPayment(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</a>
																		</div>
																	</div>
																	<a className="danger_cut" onClick={e => this.deleteCard(i,card_list)}>Delete</a>
																</div>
															)
														}, this)}
													</>
													: null}
											{(this.state.saved_card || this.state.addNewCardC2P) ?
												<div className="add_new_wrap">
													{(this.state.click2paySavedCardList.length>=1) ?
													<a onClick={e => { this.setState({ addNewCard: false, Error_Message: "",showCard:"click2pay_screen" }); }}>Back to Saved Cards</a>
													:
													<a onClick={e => { this.setState({ addNewCard: false, Error_Message: "" }); }}>Back to Saved Cards</a>
								                    }
												</div> : null
											}
											{this.state.addNewCard &&
												<div className="width_100" onKeyPress={e => this.removeError()}>
													{
														this.state.Error_Message &&
														<div className="error_message_top">{this.state.Error_Message}</div>
													}
													<div className="fpl_detail">
														<label>Card Holder Name</label>
														<input
															type="text"
															name="name"
															placeholder="Card Holder's Name"
															value={this.state.name}
															onChange={this.handleOnCardNameChange}
															style={{ borderColor: this.state.cardNameVaild ? 'red' : '#e1e1e1' }}
															ref="nameinput"
															tabIndex="5"
														/>
														{this.state.payment_error.name &&
															<span
																className="error_message_bottom_new">
																{this.state.payment_error.name}
															</span>}
													</div>
													<div className="fpl_detail">
														<div className="fpl_detail_left">
															<label>Card Number</label>
															<Cleave type="tel" style={{ borderColor: this.state.cardNumberValid ? 'red' : '#e1e1e1' }} placeholder="Enter card number" options={{ creditCard: true, onCreditCardTypeChanged: this.onCreditCardTypeChanged }}
																onChange={this.onCreditCardChange} tabIndex="1" />
															<div className="pay_pop_card_img" style={{ backgroundImage: `url(${this.state.creditCardType == 'visa' && imagePath('./visacard.png') || this.state.creditCardType == 'rupay' && imagePath('./rupaycard.png') || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && imagePath('./master.png') || this.state.creditCardType == 'diners' && imagePath('./diners.png') || this.state.creditCardType == 'amex' && imagePath('./amex.png') || this.state.creditCardType == 'discover' && imagePath("./discover.png")})` }}></div>
														</div>
														<div className="fpl_detail_mid">
															<label>Expiry</label>
															<Cleave tabIndex="2" placeholder="MM/YY" options={{ date: true, datePattern: ['m', 'y'] }}
																onKeyUp={e => { this.handleExpiryMonthYear(e); }} ref="monthyearinput"
																onChange={this.onDateChange} style={{ borderColor: this.state.cardExpiryMonthValid || this.state.cardExpiryYearValid || this.state.expiry_common ? 'red' : '#e1e1e1' }} />
														</div>
														<div className="fpl_detail_right">
															<label>CVV</label>
															{this.state.creditCardType == 'amex' ?
																<Cleave style={{ borderColor: this.state.cardCvvValid ? 'red' : '#e1e1e1' }} type="password" placeholder="••••" maxLength="4" options={{ numericOnly: true }}
																	onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
																:
																<Cleave style={{ borderColor: this.state.cardCvvValid ? 'red' : '#e1e1e1' }} type="password" placeholder="•••" maxLength="3" options={{ numericOnly: true }}
																	onChange={this.handleOnCvvChange} ref="cvvinput" name="cvvinput" tabIndex="4" />
															}
														</div>
													</div>
													{
													this.props.App_Details.customer_unique_reference !== undefined && this.props.App_Details.customer_unique_reference !== "" && this.props.App_Details.recurring_subscription !== undefined && this.props.App_Details.recurring_subscription === "No" ?
													<div className="fpl_detail">
														<input className="hide checkmark_cust" id="card_save" name="card_save" type="checkbox" checked={this.props.state.card_save}
															onChange={event => this.props.checkChange({ card_save: !this.props.state.card_save })} />
														<span className="width_100"><label htmlFor="card_save" className="custom_check_box">Save this card for future payments</label></span>
													</div>
													:
														null
													}
													{
														
														//this.props.state.click2pay_agree &&
														this.props.App_Details?.click2pay && 
										                this.state.click2payOtpListShow == true ? null:
														this.props.App_Details['click2pay'] && this.state.disablec2p &&
														<>
														<div className="fpl_detail m-t-10">
															<img className="pull-left" src={imagePath('./click-to-pay.svg')} alt="" />
														</div>
														<div className="fpl_detail m-t-10">
															<input className="hide checkmark_cust" id="click2pay_agree_card" name="click2pay_agree_card" type="checkbox" checked={this.props.state.click2pay_agree_card}
																onChange={event => this.props.checkChange({ click2pay_agree_card: !this.props.state.click2pay_agree_card })} />
																<span className="width_100"><label htmlFor="click2pay_agree_card" className="custom_check_box">I agree to share my card details, billing address and email with {this.state.creditCardType == 'visa' && "Visa" || this.state.creditCardType == 'rupay' && "Rupay" || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && "Mastercard" || this.state.creditCardType == 'diners' && "Discover" || this.state.creditCardType == 'amex' && "Amex" || this.state.creditCardType == '' && "Mastercard"} to allow me to securely enroll in
																Click to Pay for faster checkouts.</label> <a className="learnmore_txt" onClick={() => this.learnmore()}>Learn More</a></span>
														</div>
														{this.state.learnmore &&
															<src-learn-more id="src-learn-more" display-close-button="true" display-ok-button="true" locale="en_US" card-brands="mastercard,visa,amex" ></src-learn-more>
														}
														</>
													}	
														{this.props.App_Details?.click2pay &&
										     					this.state.addNewCardC2P &&
																this.props.App_Details['click2pay'] && this.state.disablec2p &&
															<>
																<div className="fpl_detail m-t-10">
																	<img className="pull-left" src={imagePath('./click-to-pay.svg')} alt="" />
																</div>
																<div className="fpl_detail m-t-10">
																	<input className="hide checkmark_cust" id="click2pay_agree_card" name="click2pay_agree_card" type="checkbox" checked={this.props.state.click2pay_agree_card}
																		onChange={event => this.props.checkChange({ click2pay_agree_card: !this.props.state.click2pay_agree_card })} />
																		<span className="width_100"><label htmlFor="click2pay_agree_card" className="custom_check_box">Yes, I want to add this card to Click to Pay. Foloosi will share my card details with {this.state.creditCardType == 'visa' && "Visa" || this.state.creditCardType == 'rupay' && "Rupay" || this.state.creditCardType == 'master' || this.state.creditCardType == 'mastercard' && "Mastercard" || this.state.creditCardType == 'diners' && "Discover" || this.state.creditCardType == 'amex' && "Amex" || this.state.creditCardType == '' && "Mastercard"}.</label></span>
																</div>
															
															</>
														}

													
												
													
													<div className={this.state.showClicktoPayWindow ? "fpl_detail_btn c2pay_card" : "fpl_detail_btn"}>
														{this.state.validExpiry && this.state.validCvv && this.state.validCardName && this.state.validCardNumber && !this.state.expiry_common ?
															<>
															{this.props.state.click2pay_agree_card ?
																<>
																<a className="pay_popup_submit" onClick={e => this.encryptCard()} >Pay Now</a>
																</>
																:
																<a className="pay_popup_submit" onClick={e => this.paynow('unsaved_card')} >Pay Now</a>
															}
															</>
															:
															<a className="pay_popup_submit_disable" onClick={e => this.showErrors()}>Pay Now</a>
														}
													</div>
												</div>
											}
										</>
										:
										this.state.showCard === "click2payNewUserFrame" ?
										<div className="fpl_detail_btn c2pay_card">
											<iframe id="clickToPayFrame" className="clickToPayFrame"></iframe>
										</div>
										:
										this.state.showCard === "click2payReturnUserFrame" ?
										<>
										<div className="fpl_detail_btn c2pay_card">
											<iframe id="clickToPayFrameReturnUser" className="clickToPayFrame"></iframe>
										</div>
										</>
										:
										this.state.showCard === "click2payOtpListShow" ?
										<>
										{/* <div className="click2payOtpListShow">
											<h5 className="otp_label">Check Email or Mobile and enter the OTP below</h5>
											<div className="fpl_detail m-t-0">
												<input type="text" name="otp" value={this.state.otp}
													onChange={event => this.handleOtpChange(event)} placeholder="Enter OTP" />
											</div>
											<div className="fpl_detail_btn">
												<button onClick={(event) => this.validateCard()}>Submit</button>
											</div>
										</div> */}
										{/*error-reason="RETRIES_EXCEEDED CODE_INVALID INVALID_ERROR_REASON"*/}
										<div className="col-xs-12 p-0" id="src-otp-input-element">
										{/* <src-otp-input id="src-otp-input" error-reason={this.state.otpErrorReason} locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" masked-identity-value={this.state.maskedValidationChannel} network-id="Mastercard" type="" is-successful="false" display-header="true" auto-submit="false" disable-elements="false" hide-loader="false" ></src-otp-input> */}
										{this.renderOTP()}
										<div className='pay_ontherway'>
										<a className='pay_resend' onClick={e => this.proceedToPaymentScreen(e)}>Pay with onther way</a>
										</div>
										</div>
										</>
										:
										this.state.showCard === "showC2POTPChannelScreen" ?
										<src-otp-channel-selection id="src-otp-channel-selection" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" type="" display-header="true"></src-otp-channel-selection>
										:
										this.state.showCard === "single_card_screen" ?
										<>
											<src-card card-art="" descriptor-name="Citi" account-number-suffix="1234" card-status="VALID" feature-text="This card participates in Citi pay with points" locale="en_US"></src-card>
										</>
										:
										this.state.showCard === "multiple_card_screen" ?
										<>
										<src-card-list id="srcCardList" locale="en_US" card-brands="mastercard,visa,amex" display-cancel-option="false" display-add-card="true" display-preferred-card="true" display-sign-out="true" card-selection-type="" src-digital-card-id="" display-header="true" background="transparent" ></src-card-list>
										</>
										:
										this.state.showCard === "click2pay_screen" ?
										<>
										{this.state.click2paySavedCardList.length >= 1 &&
											<div className="add_new_wrap">
												<div className="pull-left">
													<img className="pull-left" src={imagePath('./c2p.svg')} alt="" />
													<img className="pull-left" src={imagePath('./pipe.svg')} alt="" />
													<img className="pull-left" src={imagePath('./mastercard.svg')} alt="" />
													<img className="pull-left" src={imagePath('./visa.svg')} alt="" />
													<img className="pull-left" src={imagePath('./amex.svg')} alt="" />
												</div>
												
												<a className="btn btn-default" onClick={e => { this.setState({ showCard:"card_screen",addNewCardC2P: true, addNewCard: true, Error_Message: "",validCardName: true }) }}>Add New Card</a>
												<div className="clearfix"></div>
												<a className="not_your_cards" onClick={() => this.notYourCards()}>Not Your Cards?</a>
											</div>
											}
											{
												this.state.Error_Cvv_Message &&
												<div className="error_message_top">{this.state.Error_Cvv_Message}</div>
											}
											{this.state.click2paySavedCardList.map((card_list, p) => {
												return (
													<div className="credit-card visa selectable" key={p}>
														<input id={"credit_label_wrap"+p} className="credit_label_wrap_input" type="radio" name="credit_label_wrap"/>
														<label className="credit_label_wrap" htmlFor={"credit_label_wrap"+p}>
															<div className="credit-card-name">{card_list.digitalCardData.descriptorName} <img className="card_img" src={card_list.digitalCardData.artUri}/></div>
															<div className="credit-card-last4">
																<span className="card_no_star">**** **** ****</span> {card_list.panLastFour}
																{/* <div className="credit-card-expiry">
																	<span className="expiry_label">Expiry</span>
																	{(card_list.panExpirationMonth && card_list.panExpirationYear) &&
																	<span className="pull-left">{card_list.panExpirationMonth}/{card_list.panExpirationYear}</span>
																	}
																</div> */}
															</div>
															<div className="credit_label_wrap_btm hide">
																<div className="credit-card-cvv">
																	<input type="password" className="credit-card-cvv-input" onChange={e => this.handleCvvChange(e, p)} placeholder="Enter Cvv" onInput={e => this.onInputCvv(e)} maxLength="4"/> 
																	{/* <Cleave type="password" placeholder="Enter Cvv" maxLength="4" className="credit-card-cvv-input" options={{ numericOnly: true }} onChange={(e) => this.handleCvvChange(e,p)} ref="c2pcvvinput" name={"c2pcvvinput"+p} tabIndex="4" /> */}
																</div>
																{/* <div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div> */}
																{
																card_list.digitalCardData?.status?.toLowerCase() == 'active' ?
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																:
																card_list.digitalCardData?.status?.toLowerCase() == 'expired' ?
																<div className="error_card_list_pay">This card is expired</div>
																:
																card_list.digitalCardData?.status?.toLowerCase() == 'suspended' ?
																<div className="error_card_list_pay">Something went wrong with this card. Please contact your bank.</div>
																:
																card_list.digitalCardData?.status?.toLowerCase() == 'pending' ?
																<div className="error_card_list_pay">This card is not accepted. Please try a different payment brand.</div>
																:
																<div className="error_card_list_pay" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																}
																{/* {
																card_list.digitalCardStatus.toLowerCase() == 'active' ?
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																:
																card_list.digitalCardStatus.toLowerCase() == 'expired' ?
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																:
																card_list.digitalCardStatus.toLowerCase() == 'suspended' ?
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																:
																card_list.digitalCardStatus.toLowerCase() == 'pending' ?
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																:
																<div className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</div>
																} */}
															</div>
														</label>
													</div>
													/* <div className="save_card" key={p}>
														<div className="save_card_number">xxxx xxxx xxxx {card_list.panLastFour}
														<input className="pay_cvv_input" value={this.state.c2p_cvv} name="c2p_cvv" onChange={event => this.handleCvvChange(event)} placeholder="Enter Cvv"/>
														<a className="paynow_card" onClick={() => this.payWithReturnUserCard(card_list)}>Pay {this.props.App_Details.sender_currency} {this.props.App_Details.payable_amount_in_sender_currency}</a></div>
													</div> */
												)
											}, this)}
										</>
										:
										this.state.showCard === "apple_card_screen" ?
											<>
												{
													this.state.Apple_Error_Message &&
													<div className="error_message_top">{this.state.Apple_Error_Message}</div>
												}
												<div className="paymode_section">
													<div className="paymode_section_btn_warp">
														<a onClick={e => this.applePay()}>
															<img src={imagePath('./ApplePay_PayWith.png')} alt="" />
														</a>
													</div>
													<div className="paymode_section_split">OR</div>
													<div className="paymode_section_btn_warp">
														<a className="paymode_section_credit" onClick={e => this.goToCard()}>Pay with Card</a>
													</div>
													{this.props.App_Details.payment_modes.samsung_pay &&
													<>
													<div className="paymode_section_split">OR</div>
													<div className="paymode_section_btn_warp">
														<a onClick={e => this.samsungPay()}>
															<img src={imagePath('./samsung.png')} alt="" />
														</a>
													</div>
													</>
													}
												</div>
											</>
											:
											<>
												{this.state.showCard === "3d Redirect" &&
													<iframe id="foloosiPluginApiiframe" className="frame-open paytabs"></iframe>}
												{this.state.showCard === "ngeniusthreedsecure" &&
													<iframe id="foloosiPluginApiiframe" className="frame-open ngenius"></iframe>}
											</>
								}
							</div>
							</>
							:
							<div className="width_100" onKeyPress={(event) => event.key === 'Enter' && this.guestLogin(event)}>
								{this.props.state.loading == true &&
									<Loader />
								}
								{this.props.state.loading == false &&
									<>
										<div className="fpl_detail">
											<input type="email" name="email" value={this.props.state.Guest.email}
													onChange={event => { this.props.handleChange(event.target.name, event.target.value); this.handleChange(event); }}
													onKeyPress={e => this.handleEvent(e)} placeholder="Email" />
											{
												this.state.Guest_Error.email &&
												<span className="error_message_bottom">{this.state.Guest_Error.email}</span>
											}
										</div>
										<div className="fpl_detail">
											<input type="text" name="name" value={this.props.state.Guest.name}
												onChange={event => { this.props.handleChange(event.target.name, event.target.value); this.handleChange(event); }} onKeyPress={e => this.handleEvent(e)} placeholder="Full Name" />
											{
												this.state.Guest_Error.name &&
												<span className="error_message_bottom_new">{this.state.Guest_Error.name}</span>
											}
										</div>
										<div className="fpl_detail">
											<div className="leftWrapContPhone">
												<span className="country_flag" onClick={(e) => this.props.show_country(e)}>
													<div className="selected-flag" >
														<div className={"iti-flag " + this.props.state.selected_country.country_flag || ""}></div>
														<div className="iti-arrow"></div>
													</div>
												</span>
												<span className="phone_code">{this.props.state.selected_country.country_code || ""}</span>
												{this.props.state.countryFlag &&
													<PhoneCode showFirst={this.props.state.selected_country}
														defaultValue='select county'
														id='some-id'
														name='some-name'
														optionClassName='some option class name' />
												}
											</div>
											<div className="rightWrapContPhone">
												<input name="ph_number" type="tel"
													value={this.props.state.Guest.ph_number}
													onChange={event => { this.props.handleChange(event.target.name, event.target.value); this.handleChange(event); }} onPaste={e => this.handlePaste(e)} onKeyPress={e => { this.handleKeyEvent(e); this.handleEvent(e); }}
													placeholder="Phone" />
											</div>
											{
												this.state.Guest_Error.ph_number &&
												<span className="error_message_bottom">{this.state.Guest_Error.ph_number}</span>
											}
										</div>
										{this.state.disableguestPay === false &&
											<div className="infoWarningTextTwo"><i className="fa fa-warning"></i>Max. limit for Guest Pay – {this.props.App_Details.sender_currency} {this.props.App_Details.guest_api_limit}</div>
										}
										{/* {
											this.props.App_Details['click2pay'] &&
											<>
											<div className="fpl_detail m-t-10">
												<input className="hide checkmark_cust" id="click2pay_agree" name="click2pay_agree" type="checkbox" checked={this.props.state.click2pay_agree}
													onChange={event => this.props.checkChange({ click2pay_agree: !this.props.state.click2pay_agree })} />
												<label htmlFor="click2pay_agree" className="custom_check_box">I agree to share my card details, billing address and email with Mastercard to allow me to securely enroll in Click to Pay for faster checkouts -<img className="c2p_logo" src={imagePath('./click-to-pay.svg')} alt="" /></label>
											</div>

											</>
										} */}
										<div className="fpl_detail_btn">
											{this.state.disableguestPay === true ?
												<button onClick={(event) => this.guestLogin(event)}>PROCEED TO PAY</button>
												:
												<button disabled>PROCEED TO PAY</button>
											}
										</div>
									</>
								}
							</div>
					}
			</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	console.log("state.AppReducer",state.AppReducer)
	return {
		state: state.GuestReducer,
		App_Details: state.AppReducer.App_Details,
		queryDataObject: state.AppReducer.queryDataObject,
		card_save: state.AppReducer.card_save
	}
};

const mapDispatchToProps = dispatch => ({
	show_country: () => dispatch({ type: SHOW_COUNTRY }),
	handleChange: (name, value) => dispatch({ type: GUEST_ONCHANGE, payload: { name, value } }),
	get_country: (country) => { dispatch({ type: SELECT_COUNTRY, payload: country }); return Promise.resolve(); },
	set_country: (country) => dispatch({ type: SET_COUNTRY, payload: country }),
	guest_screen_false: () => dispatch({ type: FALSE_SCREEN }),
	guest_screen_true: () => dispatch({ type: TRUE_SCREEN }),
	set_number: (number) => dispatch({ type: SET_NUMBER, payload: number }),
	guestSignup: (header, data) => dispatch({ type: GUEST_SIGNUP, payload: dataservice.postValues(GBLVAR.API_LIST.GUEST_SIGNUP, header, data) }),
	initTrans: (header, data) => dispatch({ type: INIT_TRANS, payload: dataservice.postValues(GBLVAR.API_LIST.TRANSACTION, header, data) }),
	payment_enable: () => dispatch({ type: PAYMENT_SCREEN }),
	trimName: (name) => { dispatch({ type: TRIM_NAME, payload: name }); return Promise.resolve(); },
	checkChange: (payload) => { dispatch({ type: CHECK_CHANGE, payload }); return Promise.resolve(); },
	cardChange: (payload) => { dispatch({ type: CARD_CHANGE, payload }); return Promise.resolve(); },
	on_change: (payload) => { dispatch({ type: CHECK_CHANGE, payload }); return Promise.resolve(); },
	get_country_details: (country_details) => { dispatch({ type: GET_COUNTRY_DETAILS, payload: country_details }); return Promise.resolve(); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);